import { combineReducers } from "redux";
import intialize from "./intialize/reducer";
import shuffleData from "./shuffledata/reducer";
import trackingUser from "./trackinguser/reducer";
import screenChange from "./screentransistion/reducer";
import browserData from "./browserData/reducer";

const rootReducer = combineReducers({
	intialize,
	shuffleData,
	trackingUser,
	screenChange,
	browserData,
});

export default rootReducer;
