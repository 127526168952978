export const shuffleData = {
	audioBaseUrl: {
		vocalFemale:
			"https://tiltlabs-dev-china-image-configuration.confirmu.com/assets/levels/audio/",
		vocalMale:
			"https://tiltlabs-dev-china-image-configuration.confirmu.com/assets/levels/audioi/",
	},
	baseUrl:
		"https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh",
	dynamicSituations: [
		{
			options: [
				{
					category: "sensing",
					optionId: "charge-phone-option-op1",
					optionText: "Charge Now",
					position: "left",
					score: 0,
				},
				{
					category: "perceiving",
					optionId: "charge-phone-option-op2",
					optionText: "Charge Later",
					position: "right",
					score: 0,
				},
			],
			sceneId: "CHARGE_PHONE",
		},
		{
			options: [
				{
					category: "perceiving",
					optionId: "notification-option-op1",
					optionText: "View Message",
					position: null,
					score: 0,
				},
				{
					category: "sensing",
					optionId: "notification-option-op2",
					optionText: null,
					position: null,
					score: 0,
				},
			],
			sceneId: "NOTIFICATION",
		},
		{
			options: [
				{
					category: "sensing",
					optionId: "pickup-call-option-op1",
					optionText: "Answer",
					position: "left",
					score: 0,
				},
				{
					category: "irresponsible",
					optionId: "pickup-call-option-op2",
					optionText: "Disconnect",
					position: "right",
					score: 0,
				},
			],
			sceneId: "PICKUP_CALL",
		},
	],
	gameSessionId: "657189898cff74724fad62cd",
	imageDataSet: {
		imageAssetUrl:
			"https://tiltlabs-dev-image-configuration.confirmu.com/assets/images/game_assets/",
		imageDataList: [
			{
				content: [
					{
						id: "BUS_SEATING",
						url: "BUS_SEATING.png",
					},
					{
						id: "SCENE_PARALLAX_GROUND",
						url: "SCENE_PARALLAX_GROUND.png",
					},
					{
						id: "SCENE_PARALLAX_SKY",
						url: "SCENE_PARALLAX_SKY.jpg",
					},
					{
						id: "TREE_1",
						url: "TREE_1.png",
					},
					{
						id: "TREE_2",
						url: "TREE_2.png",
					},
					{
						id: "BIKE_POV",
						url: "BIKE_POV.png",
					},
					{
						id: "BUS_POV",
						url: "BUS_POV.png",
					},
					{
						id: "CAR_POV",
						url: "CAR_POV.png",
					},
					{
						id: "CAR_SIDES",
						url: "CAR_SIDES.png",
					},
					{
						id: "SCOOTERMAN",
						url: "SCOOTERMAN.png",
					},
					{
						id: "BG_STREET_ZOOMED_1",
						url: "BG_STREET_ZOOMED_1.jpg",
					},
					{
						id: "BG_STREET_ZOOMED_2",
						url: "BG_STREET_ZOOMED_2.jpg",
					},
					{
						id: "CAFE_PAINTING_CATALOGUE",
						url: "CAFE_PAINTING_CATALOGUE.png",
					},
					{
						id: "CAFE_POV_FOOD_1",
						url: "CAFE_POV_FOOD_1.jpg",
					},
					{
						id: "CAFE_POV_FOOD_2",
						url: "CAFE_POV_FOOD_2.jpg",
					},
					{
						id: "CAFE_POV_FRIEND",
						url: "CAFE_POV_FRIEND.jpg",
					},
					{
						id: "CAFE_SEATING",
						url: "CAFE_SEATING.jpg",
					},
					{
						id: "CAFFE_POV_EMPTY",
						url: "CAFFE_POV_EMPTY.jpg",
					},
					{
						id: "DESTINATION_POV",
						url: "DESTINATION_POV.jpg",
					},
					{
						id: "DESTINATION_STORE_CLOTHES",
						url: "DESTINATION_STORE_CLOTHES.jpg",
					},
					{
						id: "DESTINATION_STORE_DENIM",
						url: "DESTINATION_STORE_DENIM.png",
					},
					{
						id: "FUEL_STATION",
						url: "FUEL_STATION.jpg",
					},
					{
						id: "BED_BLANKET",
						url: "BED_BLANKET.png",
					},
					{
						id: "BED_TOP",
						url: "BED_TOP.jpg",
					},
					{
						id: "BG_ROOM_CUPBOARD_CLOSED",
						url: "BG_ROOM_CUPBOARD_CLOSED.jpg",
					},
					{
						id: "BG_ROOM_CUPBOARD_OPEN",
						url: "BG_ROOM_CUPBOARD_OPEN.jpg",
					},
					{
						id: "BG_ROOM_NIGHT",
						url: "BG_ROOM_NIGHT.jpg",
					},
					{
						id: "ROOM_ITEMS",
						url: "ROOM_ITEMS.jpg",
					},
					{
						id: "TAXI_SEATING",
						url: "TAXI_SEATING.png",
					},
					{
						id: "BG0_ROOM_PHONE_BACKDROP",
						url: "BG0_ROOM_PHONE_BACKDROP.jpg",
					},
					{
						id: "ASHER",
						url: "ASHER.png",
					},
				],
				type: "PROPS",
			},
		],
	},
	levels: [
		{
			level: "1",
			levelType: "Level 1",
			order: 1,
			questions: [
				{
					coins: "20",
					questionId: "tp_perceiving_04",
					questionText: "ចូលចិត្ត ឬ មិនចូលចិត្ត",
					relativeUrl: "/1/perceiving/tp_perceiving_04.jpg",
					url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/1/perceiving/tp_perceiving_04.jpg",
				},
				{
					coins: "20",
					questionId: "tp_grandiosity_02",
					questionText: "ចូលចិត្ត ឬ មិនចូលចិត្ត",
					relativeUrl: "/1/grandiosity/tp_grandiosity_02.jpg",
					url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/1/grandiosity/tp_grandiosity_02.jpg",
				},
				{
					coins: "20",
					questionId: "tp_integrity_05",
					questionText: "ចូលចិត្ត ឬ មិនចូលចិត្ត",
					relativeUrl: "/1/integrity/tp_integrity_05.jpg",
					url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/1/integrity/tp_integrity_05.jpg",
				},
				{
					coins: "20",
					questionId: "tp_intuition_05",
					questionText: "ចូលចិត្ត ឬ មិនចូលចិត្ត",
					relativeUrl: "/1/intuition/tp_intuition_05.jpg",
					url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/1/intuition/tp_intuition_05.jpg",
				},
				{
					coins: "20",
					questionId: "tp_neuroticism_05",
					questionText: "ចូលចិត្ត ឬ មិនចូលចិត្ត",
					relativeUrl: "/1/neuroticism/tp_neuroticism_05.jpg",
					url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/1/neuroticism/tp_neuroticism_05.jpg",
				},
				{
					coins: "20",
					questionId: "tp_intuition_01",
					questionText: "ចូលចិត្ត ឬ មិនចូលចិត្ត",
					relativeUrl: "/1/intuition/tp_intuition_01.jpg",
					url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/1/intuition/tp_intuition_01.jpg",
				},
				{
					coins: "20",
					questionId: "tp_judging_05",
					questionText: "ចូលចិត្ត ឬ មិនចូលចិត្ត",
					relativeUrl: "/1/judging/tp_judging_05.jpg",
					url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/1/judging/tp_judging_05.jpg",
				},
				{
					coins: "20",
					questionId: "tp_sensing_02",
					questionText: "ចូលចិត្ត ឬ មិនចូលចិត្ត",
					relativeUrl: "/1/sensing/tp_sensing_02.jpg",
					url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/1/sensing/tp_sensing_02.jpg",
				},
				{
					coins: "20",
					questionId: "tp_judging_01",
					questionText: "ចូលចិត្ត ឬ មិនចូលចិត្ត",
					relativeUrl: "/1/judging/tp_judging_01.jpg",
					url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/1/judging/tp_judging_01.jpg",
				},
				{
					coins: "20",
					questionId: "tp_integrity_01",
					questionText: "ចូលចិត្ត ឬ មិនចូលចិត្ត",
					relativeUrl: "/1/integrity/tp_integrity_01.jpg",
					url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/1/integrity/tp_integrity_01.jpg",
				},
				{
					coins: "20",
					questionId: "tp_perceiving_03",
					questionText: "ចូលចិត្ត ឬ មិនចូលចិត្ត",
					relativeUrl: "/1/perceiving/tp_perceiving_03.jpg",
					url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/1/perceiving/tp_perceiving_03.jpg",
				},
				{
					coins: "20",
					questionId: "tp_neuroticism_03",
					questionText: "ចូលចិត្ត ឬ មិនចូលចិត្ត",
					relativeUrl: "/1/neuroticism/tp_neuroticism_03.jpg",
					url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/1/neuroticism/tp_neuroticism_03.jpg",
				},
				{
					coins: "20",
					questionId: "tp_sensing_04",
					questionText: "ចូលចិត្ត ឬ មិនចូលចិត្ត",
					relativeUrl: "/1/sensing/tp_sensing_04.jpg",
					url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/1/sensing/tp_sensing_04.jpg",
				},
				{
					coins: "20",
					questionId: "tp_grandiosity_04",
					questionText: "ចូលចិត្ត ឬ មិនចូលចិត្ត",
					relativeUrl: "/1/grandiosity/tp_grandiosity_04.jpg",
					url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/1/grandiosity/tp_grandiosity_04.jpg",
				},
			],
		},
		{
			level: "2",
			levelType: "numeracy",
			order: 2,
			questions: [
				{
					QuestionAudioFemale: null,
					QuestionAudioMale: "wallet-lang-set-2.mp3",
					correctOptionId: "wallet-options-6-op1",
					options: [
						{
							optionId: "wallet-options-6-op1",
							optionImageRelativeUrl: null,
							optionImageUrl: null,
							optionText: "302,500",
							score: 20,
						},
						{
							optionId: "wallet-options-6-op2",
							optionImageRelativeUrl: null,
							optionImageUrl: null,
							optionText: "100,000",
							score: 0,
						},
						{
							optionId: "wallet-options-6-op3",
							optionImageRelativeUrl: null,
							optionImageUrl: null,
							optionText: "200,000",
							score: 0,
						},
					],
					parentQuestionId: null,
					questionId: "wallet-options-6",
					questionImageRelativeUrl: null,
					questionImageUrl: null,
					questionSetId: "wallet-options",
					questionText:
						"ឥឡូវនេះប្រសិនបើអ្នកបើកទូរដាក់លុយរបស់អ្នក ហើយអ្នកទាញមកមានលុយក្រដាស  ១០០ ចំនួន​ ២០០០ សន្លឹក, ក្រដាស ២០០ ចំនួន ៥០០ សន្លឹក, ក្រដាស ៥០០ ចំនួន ៥ សន្លឹក, សរុប តើអ្នកមានលុយប៉ុន្មាន?",
				},
				{
					QuestionAudioFemale: null,
					QuestionAudioMale: "cab-charge-5.mp3",
					correctOptionId: "cab-charge-5-op1",
					options: [
						{
							optionId: "cab-charge-5-op1",
							optionImageRelativeUrl: null,
							optionImageUrl: null,
							optionText: "15,000",
							score: 20,
						},
						{
							optionId: "cab-charge-5-op2",
							optionImageRelativeUrl: null,
							optionImageUrl: null,
							optionText: "12,000",
							score: 0,
						},
						{
							optionId: "cab-charge-5-op3",
							optionImageRelativeUrl: null,
							optionImageUrl: null,
							optionText: "13,000",
							score: 0,
						},
					],
					parentQuestionId: null,
					questionId: "cab-charge-5",
					questionImageRelativeUrl: null,
					questionImageUrl: null,
					questionSetId: "cab-charge",
					questionText:
						"ថ្លៃ កង់បី  របស់អ្នកអស់ ៥០០០ រៀល, អ្នកឲ្យគេ ២០០០០ រៀល, តើគេត្រូវអាប់ឲ្យអ្នកវិញប៉ុន្មាន?",
				},
				{
					QuestionAudioFemale: null,
					QuestionAudioMale: "bus-charge-5.mp3",
					correctOptionId: "bus-charge-5-op1",
					options: [
						{
							optionId: "bus-charge-5-op1",
							optionImageRelativeUrl: null,
							optionImageUrl: null,
							optionText: "7000",
							score: 20,
						},
						{
							optionId: "bus-charge-5-op2",
							optionImageRelativeUrl: null,
							optionImageUrl: null,
							optionText: "3000",
							score: 0,
						},
						{
							optionId: "bus-charge-5-op3",
							optionImageRelativeUrl: null,
							optionImageUrl: null,
							optionText: "4000",
							score: 0,
						},
					],
					parentQuestionId: null,
					questionId: "bus-charge-5",
					questionImageRelativeUrl: null,
					questionImageUrl: null,
					questionSetId: "bus-charge",
					questionText:
						"ថ្លៃ កង់បី របស់អ្នកអស់ ៣០០០ រៀល, អ្នកឲ្យគេ ១០០០០ រៀល, តើគេត្រូវអាប់ឲ្យអ្នកវិញប៉ុន្មាន?",
				},
				{
					QuestionAudioFemale: null,
					QuestionAudioMale: "scooter-petrol-balance-253-3.mp3",
					correctOptionId: "scooter-petrol-balance-253-3-op1",
					options: [
						{
							optionId: "scooter-petrol-balance-253-3-op1",
							optionImageRelativeUrl: null,
							optionImageUrl: null,
							optionText: "8000",
							score: 20,
						},
						{
							optionId: "scooter-petrol-balance-253-3-op2",
							optionImageRelativeUrl: null,
							optionImageUrl: null,
							optionText: "7000",
							score: 0,
						},
						{
							optionId: "scooter-petrol-balance-253-3-op3",
							optionImageRelativeUrl: null,
							optionImageUrl: null,
							optionText: "6000",
							score: 0,
						},
					],
					parentQuestionOptionId: "scooter-petrol-fill-options-3-op3",
					questionId: "scooter-petrol-balance-253-3",
					questionImageRelativeUrl: null,
					questionImageUrl: null,
					questionSetId: "scooter-petrol-fill-options",
					questionText:
						"អ្នកចាក់សាំងអស់ ៣ លីត្រ, ហើយអ្នកត្រូវឲ្យលុយគេចំនួន ១២០០០ រៀល។ អ្នកឲ្យលុយទៅគេចំនួន ២០០០០ រៀល តើគេត្រូវអាប់លុយឲ្យអ្នកវិញចំនួនប៉ុន្មាន?",
				},
				{
					QuestionAudioFemale: null,
					QuestionAudioMale: "car-petrol-balance-91211-2.mp3",
					correctOptionId: "car-petrol-balance-91211-2-op1",
					options: [
						{
							optionId: "car-petrol-balance-91211-2-op1",
							optionImageRelativeUrl: null,
							optionImageUrl: null,
							optionText: "2000",
							score: 20,
						},
						{
							optionId: "car-petrol-balance-91211-2-op2",
							optionImageRelativeUrl: null,
							optionImageUrl: null,
							optionText: "1000",
							score: 0,
						},
						{
							optionId: "car-petrol-balance-91211-2-op3",
							optionImageRelativeUrl: null,
							optionImageUrl: null,
							optionText: "1200",
							score: 0,
						},
					],
					parentQuestionOptionId: "car-petrol-fill-options-3-op2",
					questionId: "car-petrol-balance-91211-2",
					questionImageRelativeUrl: null,
					questionImageUrl: null,
					questionSetId: "car-petrol-fill-options",
					questionText:
						"អ្នកចាក់សាំងអស់ ១២ លីត្រ, ហើយអ្នកត្រូវឲ្យលុយគេចំនួន ៤៨០០០ រៀល។ អ្នកឲ្យលុយទៅគេចំនួន ៥០០០០ រៀល តើគេត្រូវអាប់លុយឲ្យអ្នកវិញចំនួនប៉ុន្មាន?",
				},
				{
					QuestionAudioFemale: null,
					QuestionAudioMale: "restaurant-tip-options.mp3",
					options: [
						{
							optionId: "restaurant-tip-options-1-op1",
							optionImageRelativeUrl: null,
							optionImageUrl: null,
							optionText: "",
						},
						{
							optionId: "restaurant-tip-options-1-op2",
							optionImageRelativeUrl: null,
							optionImageUrl: null,
							optionText: "",
						},
						{
							optionId: "restaurant-tip-options-1-op3",
							optionImageRelativeUrl: null,
							optionImageUrl: null,
							optionText: "",
						},
					],
					parentQuestionId: null,
					questionId: "restaurant-tip-options-1",
					questionImageRelativeUrl: null,
					questionImageUrl: null,
					questionSetId: "restaurant-tip-options",
					questionText: "តើអ្នកចង់អោយធីបគេប៉ុន្មាន?",
				},
				{
					QuestionAudioFemale: null,
					QuestionAudioMale: "banana-lang-set-2.mp3",
					correctOptionId: "banana-options-5-op1",
					options: [
						{
							optionId: "banana-options-5-op1",
							optionImageRelativeUrl: null,
							optionImageUrl: null,
							optionText: "40",
							score: 20,
						},
						{
							optionId: "banana-options-5-op2",
							optionImageRelativeUrl: null,
							optionImageUrl: null,
							optionText: "39",
							score: 0,
						},
						{
							optionId: "banana-options-5-op3",
							optionImageRelativeUrl: null,
							optionImageUrl: null,
							optionText: "32",
							score: 0,
						},
					],
					parentQuestionId: null,
					questionId: "banana-options-5",
					questionImageRelativeUrl: null,
					questionImageUrl: null,
					questionSetId: "banana-options",
					questionText:
						"ប្រសិនជាអ្នកទិញ ផ្លែចេកចំនួន ១២ ផ្លែ, ក្រូច ១៣ ផ្លែ, និង​ ផ្លែប៉ោម ១៥ ផ្លែ, តើសរុបអ្នកមានផ្លែឈើប៉ុន្មានផ្លែ?",
				},
				{
					QuestionAudioFemale: null,
					QuestionAudioMale: "restaurant-tip-options-5.mp3",
					correctOptionId: "restaurant-tip-options-5-1-op3",
					options: [
						{
							optionId: "restaurant-tip-options-5-1-op1",
							optionImageRelativeUrl: null,
							optionImageUrl: null,
							optionText: "10%",
							score: 0,
						},
						{
							optionId: "restaurant-tip-options-5-1-op2",
							optionImageRelativeUrl: null,
							optionImageUrl: null,
							optionText: "12%",
							score: 0,
						},
						{
							optionId: "restaurant-tip-options-5-1-op3",
							optionImageRelativeUrl: null,
							optionImageUrl: null,
							optionText: "15%",
							score: 20,
						},
					],
					parentQuestionId: null,
					parentQuestionOptionId: "restaurant-tip-options-1-op1",
					questionId: "restaurant-tip-options-5-1",
					questionImageRelativeUrl: null,
					questionImageUrl: null,
					questionText:
						"សរុបទាំងអស់ថ្លៃកាហ្វេរបស់អ្នក អស់ចំនួន ៤០០០០ រៀល។ អ្នកចង់អោយធីបអ្នករត់តុ ៤០០០ រៀល, តើចំនួន ៤០០០ រៀលនេះ ស្មើរនិងប៉ុន្មានភាគរយនៃថ្លៃរបស់ដែលបង់?",
				},
				{
					QuestionAudioFemale: null,
					QuestionAudioMale: "restaurant-tip-options-10.mp3",
					correctOptionId: "restaurant-tip-options-10-1-op2",
					options: [
						{
							optionId: "restaurant-tip-options-10-1-op1",
							optionImageRelativeUrl: null,
							optionImageUrl: null,
							optionText: "10%",
							score: 0,
						},
						{
							optionId: "restaurant-tip-options-10-1-op2",
							optionImageRelativeUrl: null,
							optionImageUrl: null,
							optionText: "5%",
							score: 20,
						},
						{
							optionId: "restaurant-tip-options-10-1-op3",
							optionImageRelativeUrl: null,
							optionImageUrl: null,
							optionText: "15%",
							score: 0,
						},
					],
					parentQuestionId: null,
					parentQuestionOptionId: "restaurant-tip-options-1-op2",
					questionId: "restaurant-tip-options-10-1",
					questionImageRelativeUrl: null,
					questionImageUrl: null,
					questionText:
						"សរុបទាំងអស់ថ្លៃកាហ្វេរបស់អ្នក អស់ចំនួន ២០០០០ រៀល។ អ្នកចង់អោយធីបអ្នករត់តុ ២០០០ រៀល, តើចំនួន ២០០០ រៀលនេះ ស្មើរនិងប៉ុន្មានភាគរយនៃថ្លៃរបស់ដែលបង់?",
				},
				{
					QuestionAudioFemale: null,
					QuestionAudioMale: "restaurant-tip-options-15.mp3",
					correctOptionId: "restaurant-tip-options-15-1-op1",
					options: [
						{
							optionId: "restaurant-tip-options-15-1-op1",
							optionImageRelativeUrl: null,
							optionImageUrl: null,
							optionText: "8%",
							score: 20,
						},
						{
							optionId: "restaurant-tip-options-15-1-op2",
							optionImageRelativeUrl: null,
							optionImageUrl: null,
							optionText: "10%",
							score: 0,
						},
						{
							optionId: "restaurant-tip-options-15-1-op3",
							optionImageRelativeUrl: null,
							optionImageUrl: null,
							optionText: "5%",
							score: 0,
						},
					],
					parentQuestionId: null,
					parentQuestionOptionId: "restaurant-tip-options-1-op3",
					questionId: "restaurant-tip-options-15-1",
					questionImageRelativeUrl: null,
					questionImageUrl: null,
					questionText:
						"សរុបទាំងអស់ថ្លៃកាហ្វេរបស់អ្នក អស់ចំនួន ៥០០០០ រៀល។ អ្នកចង់អោយធីបអ្នករត់តុ ៤០០០ រៀល, តើចំនួន ៤០០០ រៀលនេះ ស្មើរនិងប៉ុន្មានភាគរយនៃថ្លៃរបស់ដែលបង់?",
				},
			],
		},
		{
			level: "3",
			levelType: "Level 3",
			order: 3,
			questions: [
				{
					coins: "40",
					narratedBy: "SYSTEM",
					options: [
						{
							optionId: "set6left2",
							relativeUrl: "/2/set6/2/set6left2.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/2/set6/2/set6left2.jpg",
						},
						{
							optionId: "set6right2",
							relativeUrl: "/2/set6/2/set6right2.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/2/set6/2/set6right2.jpg",
						},
					],
					questionAudioFemale: null,
					questionAudioMale: null,
					questionId: "two-image-selection",
					questionText: "តើអ្នកទិញរូបភាពមួយណា",
				},
				{
					coins: "40",
					narratedBy: "SYSTEM",
					options: [
						{
							optionId: "set4left1",
							relativeUrl: "/2/set4/1/set4left1.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/2/set4/1/set4left1.jpg",
						},
						{
							optionId: "set4right1",
							relativeUrl: "/2/set4/1/set4right1.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/2/set4/1/set4right1.jpg",
						},
					],
					questionAudioFemale: null,
					questionAudioMale: null,
					questionId: "two-image-selection",
					questionText: "តើអ្នកទិញរូបភាពមួយណា",
				},
				{
					coins: "40",
					narratedBy: "SYSTEM",
					options: [
						{
							optionId: "set1left3",
							relativeUrl: "/2/set1/3/set1left3.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/2/set1/3/set1left3.jpg",
						},
						{
							optionId: "set1right3",
							relativeUrl: "/2/set1/3/set1right3.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/2/set1/3/set1right3.jpg",
						},
					],
					questionAudioFemale: null,
					questionAudioMale: null,
					questionId: "two-image-selection",
					questionText: "តើអ្នកទិញរូបភាពមួយណា",
				},
				{
					coins: "40",
					narratedBy: "SYSTEM",
					options: [
						{
							optionId: "set2left1",
							relativeUrl: "/2/set2/1/set2left1.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/2/set2/1/set2left1.jpg",
						},
						{
							optionId: "set2right1",
							relativeUrl: "/2/set2/1/set2right1.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/2/set2/1/set2right1.jpg",
						},
					],
					questionAudioFemale: null,
					questionAudioMale: null,
					questionId: "two-image-selection",
					questionText: "តើអ្នកទិញរូបភាពមួយណា",
				},
				{
					coins: "40",
					narratedBy: "SYSTEM",
					options: [
						{
							optionId: "set5left2",
							relativeUrl: "/2/set5/2/set5left2.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/2/set5/2/set5left2.jpg",
						},
						{
							optionId: "set5right2",
							relativeUrl: "/2/set5/2/set5right2.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/2/set5/2/set5right2.jpg",
						},
					],
					questionAudioFemale: null,
					questionAudioMale: null,
					questionId: "two-image-selection",
					questionText: "តើអ្នកទិញរូបភាពមួយណា",
				},
				{
					coins: "40",
					narratedBy: "SYSTEM",
					options: [
						{
							optionId: "set7left1",
							relativeUrl: "/2/set7/1/set7left1.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/2/set7/1/set7left1.jpg",
						},
						{
							optionId: "set7right1",
							relativeUrl: "/2/set7/1/set7right1.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/2/set7/1/set7right1.jpg",
						},
					],
					questionAudioFemale: null,
					questionAudioMale: null,
					questionId: "two-image-selection",
					questionText: "តើអ្នកទិញរូបភាពមួយណា",
				},
				{
					coins: "40",
					narratedBy: "SYSTEM",
					options: [
						{
							optionId: "set3left2",
							relativeUrl: "/2/set3/2/set3left2.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/2/set3/2/set3left2.jpg",
						},
						{
							optionId: "set3right2",
							relativeUrl: "/2/set3/2/set3right2.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/2/set3/2/set3right2.jpg",
						},
					],
					questionAudioFemale: null,
					questionAudioMale: null,
					questionId: "two-image-selection",
					questionText: "តើអ្នកទិញរូបភាពមួយណា",
				},
			],
		},
		{
			level: "4",
			levelType: "Level 4",
			order: 4,
			questions: [
				{
					coins: "50",
					narratedBy: "AVATAR",
					options: [
						{
							optionId: "set30012",
							relativeUrl: "/3/set30/2/set30012.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/3/set30/2/set30012.jpg",
						},
						{
							optionId: "set30022",
							relativeUrl: "/3/set30/2/set30022.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/3/set30/2/set30022.jpg",
						},
						{
							optionId: "set30032",
							relativeUrl: "/3/set30/2/set30032.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/3/set30/2/set30032.jpg",
						},
					],
					questionAudioFemale: null,
					questionAudioMale: null,
					questionId: "three-image-selection",
					questionText: "",
				},
				{
					coins: "50",
					narratedBy: "NPC",
					options: [
						{
							optionId: "set37012",
							relativeUrl: "/3/set37/2/set37012.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/3/set37/2/set37012.jpg",
						},
						{
							optionId: "set37022",
							relativeUrl: "/3/set37/2/set37022.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/3/set37/2/set37022.jpg",
						},
						{
							optionId: "set37032",
							relativeUrl: "/3/set37/2/set37032.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/3/set37/2/set37032.jpg",
						},
					],
					questionAudioFemale: null,
					questionAudioMale: null,
					questionId: "three-image-selection",
					questionText: "",
				},
				{
					coins: "50",
					narratedBy: "NPC",
					options: [
						{
							optionId: "set36011",
							relativeUrl: "/3/set36/1/set36011.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/3/set36/1/set36011.jpg",
						},
						{
							optionId: "set36021",
							relativeUrl: "/3/set36/1/set36021.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/3/set36/1/set36021.jpg",
						},
						{
							optionId: "set36031",
							relativeUrl: "/3/set36/1/set36031.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/3/set36/1/set36031.jpg",
						},
					],
					questionAudioFemale: null,
					questionAudioMale: null,
					questionId: "three-image-selection",
					questionText: "",
				},
				{
					coins: "50",
					narratedBy: "NPC",
					options: [
						{
							optionId: "set34011",
							relativeUrl: "/3/set34/1/set34011.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/3/set34/1/set34011.jpg",
						},
						{
							optionId: "set34021",
							relativeUrl: "/3/set34/1/set34021.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/3/set34/1/set34021.jpg",
						},
						{
							optionId: "set34031",
							relativeUrl: "/3/set34/1/set34031.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/3/set34/1/set34031.jpg",
						},
					],
					questionAudioFemale: null,
					questionAudioMale: null,
					questionId: "three-image-selection",
					questionText: "",
				},
				{
					coins: "50",
					narratedBy: "NPC",
					options: [
						{
							optionId: "set35012",
							relativeUrl: "/3/set35/2/set35012.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/3/set35/2/set35012.jpg",
						},
						{
							optionId: "set35022",
							relativeUrl: "/3/set35/2/set35022.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/3/set35/2/set35022.jpg",
						},
						{
							optionId: "set35032",
							relativeUrl: "/3/set35/2/set35032.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/3/set35/2/set35032.jpg",
						},
					],
					questionAudioFemale: null,
					questionAudioMale: null,
					questionId: "three-image-selection",
					questionText: "",
				},
				{
					coins: "50",
					narratedBy: "AVATAR",
					options: [
						{
							optionId: "set32011",
							relativeUrl: "/3/set32/1/set32011.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/3/set32/1/set32011.jpg",
						},
						{
							optionId: "set32021",
							relativeUrl: "/3/set32/1/set32021.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/3/set32/1/set32021.jpg",
						},
						{
							optionId: "set32031",
							relativeUrl: "/3/set32/1/set32031.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/3/set32/1/set32031.jpg",
						},
					],
					questionAudioFemale: null,
					questionAudioMale: null,
					questionId: "three-image-selection",
					questionText: "",
				},
				{
					coins: "50",
					narratedBy: "AVATAR",
					options: [
						{
							optionId: "set33011",
							relativeUrl: "/3/set33/1/set33011.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/3/set33/1/set33011.jpg",
						},
						{
							optionId: "set33021",
							relativeUrl: "/3/set33/1/set33021.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/3/set33/1/set33021.jpg",
						},
						{
							optionId: "set33031",
							relativeUrl: "/3/set33/1/set33031.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/3/set33/1/set33031.jpg",
						},
					],
					questionAudioFemale: null,
					questionAudioMale: null,
					questionId: "three-image-selection",
					questionText: "",
				},
				{
					coins: "50",
					narratedBy: "AVATAR",
					options: [
						{
							optionId: "set31011",
							relativeUrl: "/3/set31/1/set31011.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/3/set31/1/set31011.jpg",
						},
						{
							optionId: "set31021",
							relativeUrl: "/3/set31/1/set31021.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/3/set31/1/set31021.jpg",
						},
						{
							optionId: "set31031",
							relativeUrl: "/3/set31/1/set31031.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/3/set31/1/set31031.jpg",
						},
					],
					questionAudioFemale: null,
					questionAudioMale: null,
					questionId: "three-image-selection",
					questionText: "",
				},
				{
					coins: "50",
					narratedBy: "AVATAR",
					options: [
						{
							optionId: "set38012",
							relativeUrl: "/3/set38/2/set38012.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/3/set38/2/set38012.jpg",
						},
						{
							optionId: "set38022",
							relativeUrl: "/3/set38/2/set38022.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/3/set38/2/set38022.jpg",
						},
						{
							optionId: "set38032",
							relativeUrl: "/3/set38/2/set38032.jpg",
							url: "https://tiltlabs-dev-image-configuration.confirmu.com/assets/levels-kh/3/set38/2/set38032.jpg",
						},
					],
					questionAudioFemale: null,
					questionAudioMale: null,
					questionId: "three-image-selection",
					questionText: "",
				},
			],
		},
	],
	narrationDataSet: {
		femaleNarrationAssetUrl:
			"https://tiltlabs-dev-china-image-configuration.confirmu.com/assets/levels/audio-km/female/",
		narrationAssetUrl:
			"https://tiltlabs-dev-china-image-configuration.confirmu.com/assets/levels/audio-km/",
		narrationDataList: [
			{
				audioClip: "P2C_INTRO.mp3",
				audioClipFemale: null,
				duration: 0,
				id: "TEXT_BODYLESS_INTRO_1",
				message:
					"អបអរសាទរចំពោះផ្ទះថ្មីរបស់អ្នក!​ ឥឡូវនេះ ឧទាហរណ៍ថា កម្មវិធីជប់លៀងឡើងផ្ទះថ្មីរបស់អ្នកនឹងមកដល់ក្នុងពេលឆាប់ៗខាងមុខនេះ ដូច្នេះហើយ បន្តិចទៀតនេះអ្នកនឹងទៅផ្សារដើម្បីរកទិញឥវ៉ាន់មួយចំនួនសម្រាប់ខ្លួនអ្នក និង សម្រាប់តាំងលំអផ្ទះរបស់អ្នក។​ ពេលទៅផ្សារ អ្នកក៏នឹងបានជួបមិត្តភក្តិរបស់អ្នក​ផងដែរ។​ តោះទៅ!",
			},
			{
				audioClip: "ASHER_INTRO.mp3",
				audioClipFemale: null,
				duration: 0,
				id: "TEXT_ASHER_INTRO_1",
				message:
					"ខ្ញុំគឺជាជំនួយការរបស់អ្នក ហើយខ្ញុំនឹងប្រាប់អ្នកអំពីដំណាក់កាលនីមួយៗ ដែលអ្នកនឹងត្រូវបំពេញនាពេលបន្តិចទៀតនេះ។ សូមធ្វើអ្វីគ្រប់យ៉ាងទៅតាមការយល់ឃើញរបស់អ្នក។ សូមកុំបារម្ភ មិនមានការវាយតម្លៃថាអ្នកធ្វើបានល្អ ឬ មិនល្អនោះទេ។​",
			},
			{
				audioClip: "EXPLAIN _TOKENS.mp3",
				audioClipFemale: null,
				duration: 0,
				id: "TEXT_ASHER_TUTORIAL_1",
				message: "ពិន្ទុ",
			},
			{
				audioClip: "PROGRESS_BAR.mp3",
				audioClipFemale: null,
				duration: 0,
				id: "TEXT_ASHER_TUTORIAL_2",
				message: "ដំណាក់កាល",
			},
			{
				audioClip: "TIME_TAKEN.mp3",
				audioClipFemale: null,
				duration: 0,
				id: "TEXT_ASHER_TUTORIAL_3",
				message: "រយៈពេល",
			},
			{
				audioClip: "ASHER_PLAYMOJI.mp3",
				audioClipFemale: null,
				duration: 0,
				id: "ASHER_PLAYMOJI",
				message:
					"សូមជ្រើសរើសរូបតំណាងមួយដែលអ្នកគិតថាត្រូវ ឬ ប្រហាក់ប្រហែលនឹងខ្លួនអ្នកជាងគេ",
			},
			{
				audioClip: "ASHER_SHOP_NOW.mp3",
				audioClipFemale: null,
				duration: 0,
				id: "ASHER_SHOP_NOW",
				message:
					"ឥឡូវនេះគេមានការលក់បញ្ចុះតម្លៃនូវសម្ភារជាច្រើន ដែលអ្នកអាចទិញសម្រាប់ខ្លួនអ្នក និង សម្រាប់ផ្ទះរបស់អ្នក។ តោះទិញរបស់ខ្លះ កុំឲ្យឱកាសនេះរំលងផុតទៅអី ស្ដាយការបញ្ចុះតម្លៃ! ",
			},
			{
				audioClip: "ASHER_SELECT_TRAVEL.mp3",
				audioClipFemale: null,
				duration: 0,
				id: "ASHER_SELECT_TRAVEL",
				message: "តើអ្នកចង់ធ្វើដំណើរទៅផ្សារតាមវិធីណាមួយ?",
			},
			{
				audioClip: "ASHER_CAFE_ORGANICA.mp3",
				audioClipFemale: null,
				duration: 0,
				id: "ASHER_CAFE",
				message: "ល្អណាស់! ឥឡូវនេះយើងមកដល់ផ្សារហើយ។",
			},
			{
				audioClip: "ASHER_SHOPPING.mp3",
				audioClipFemale: null,
				duration: 0,
				id: "ASHER_SHOPPING",
				message:
					"ហា...មើលហាងលក់សំលៀកបំពាក់នោះ ដូចជាទំនងណាស់, តោះចូលទៅទិញសំលៀកបំពាក់ទុកខ្លះ។",
			},
			{
				audioClip: "AVATAR_COST_JEANS_1.mp3",
				audioClipFemale: "AVATAR_COST_JEANS_1.mp3",
				duration: 0,
				id: "BARGAIN_AVATAR_1",
				message: "និយាយអ៊ីចឹង ខោខូប៊យ និង អាវយឺតនេះ តម្លៃប៉ុន្មានដែរបង?",
			},
			{
				audioClip: "SHOPKEEPER_QUOTE.mp3",
				audioClipFemale: null,
				duration: 0,
				id: "BARGAIN_SHOPKEEPER_1",
				message: "60000 រៀល",
			},
			{
				audioClip: "ASHER_BUY_BARGAIN.mp3",
				audioClipFemale: null,
				duration: 0,
				id: "BARGAIN_ASHER",
				message: "អ្នកចង់តថ្លៃដែរ ឬ ទេ?",
			},
			{
				audioClip: "AVATAR_QUOTE_JEANS_1.mp3",
				audioClipFemale: "AVATAR_QUOTE_JEANS_1.mp3",
				duration: 0,
				id: "BARGAIN_AVATAR_2",
				message: "ហា... ដូចជារាងថ្លៃបន្តិចហើយ ចុះខ្លះបានទេ?",
			},
			{
				audioClip: "BARGAIN_SHOPKEEPER_2.mp3",
				audioClipFemale: null,
				duration: 0,
				id: "BARGAIN_SHOPKEEPER_2",
				message: "ល្អ ចាប់ខ្ញុំវិចខ្ចប់អោយសិន",
			},
			{
				audioClip: "SHOPKEEPER_QUOTE_JEANS_2.mp3",
				audioClipFemale: null,
				duration: 0,
				id: "BARGAIN_SHOPKEEPER_3",
				message: "55000 រៀល",
			},
			{
				audioClip: "AVATAR_QUOTE_JEANS_2.mp3",
				audioClipFemale: "AVATAR_QUOTE_JEANS_2.mp3",
				duration: 0,
				id: "BARGAIN_AVATAR_3",
				message: "តើអ្នកអាចចុះតិចទៀតបានទេ?",
			},
			{
				audioClip: "SHOPKEEPER_QUOTE_JEANS_3.mp3",
				audioClipFemale: null,
				duration: 0,
				id: "BARGAIN_SHOPKEEPER_4",
				message: "ទៅខ្ញុំចុះអោយបងបន្តិចទៀតអស់ហើយ៖ 50000 រៀល",
			},
			{
				audioClip: "AVATAR_CHECK_PICTUREGRAM.mp3",
				audioClipFemale: "AVATAR_CHECK_PICTUREGRAM.mp3",
				duration: 0,
				id: "TEXT_PICTUREGRAM",
				message:
					"ឥឡូវនេះអ្នកកំពុងអង្គុយរង់ចាំមិត្តរបស់អ្នក, ក្នុងកំឡុងពេលរង់ចាំនោះ អ្នកក៏មើលរូបភាពក្នុង ហ្វេសប៊ុករបស់អ្នក​ សូមចុចបន្តដើម្បីមើលរូបភាពទាំងនោះ។ សូមរើសរូបភាពដែលអ្នកចូលចិត្ត ឬ មិនចូលចិត្ត។",
			},
			{
				audioClip: "AVATAR_RAHUL.mp3",
				audioClipFemale: "AVATAR_RAHUL.mp3",
				duration: 0,
				id: "AVATAR_RAHUL",
				message:
					"វាសនា មកដល់ល្មម។មើ! វាសនា គ្នាចង់ឲ្យវាសនាឯងជួយគិតបន្តិច តើខ្ញុំគួររើសយករូបភាពមួយណាដែលសាកសមនឹងផ្ទះរបស់ខ្ញុំ។",
			},
			{
				audioClip: "RAHUL_AVATAR.mp3",
				audioClipFemale: null,
				duration: 0,
				id: "RAHUL_AVATAR",
				message:
					"បានតើ តោះមើលរូបភាពទាំងអស់នេះទៅមើ! ហើយរើសមួយណាដែលឯងពេញចិត្តទៅជាការស្រេចហើយ។",
			},
			{
				audioClip: "AVATAR_CAFE_ASK_BILL.mp3",
				audioClipFemale: "AVATAR_CAFE_ASK_BILL.mp3",
				duration: 0,
				id: "CONVERSATION_AVATAR_3",
				message:
					"តោះ គ្នាត្រូវទៅផ្ទះវិញម៉ោង ៤ រសៀលនេះវ៉ឺយ តោះគិតលុយតែម្តងទៅអ៊ីចឹង។​",
			},
			{
				audioClip: "FRIEND_CAFE_ASK_BILL_REPLY.mp3",
				audioClipFemale: null,
				duration: 0,
				id: "CONVERSATION_NPC_4",
				message: "បានតើ",
			},
			{
				audioClip: "AVATAR_THINKING_FRUITS.mp3",
				audioClipFemale: "AVATAR_THINKING_FRUITS.mp3",
				duration: 0,
				id: "TEXT_GROCERY",
				message:
					"ហា!....មើលទៅផ្លែឈើនោះទំនងណាស់ មើលទៅទិញខ្លះទុកសម្រាប់កម្មវិធីស្អែកបន្តិចមើ!",
			},
			{
				audioClip: "ASHER_SUBMIT.mp3",
				audioClipFemale: null,
				duration: 0,
				id: "ASHER_SUBMIT",
				message: "ឥឡូវផ្ទះរបស់អ្នករៀបចំរួចរាល់ហើយ ស្អាតណាស់!",
			},
			{
				audioClip: "FINAL_SCREEN_MESSAGE.mp3",
				audioClipFemale: null,
				duration: 0,
				id: "FINAL_SCREEN_MESSAGE",
				message: "អរគុណសម្រាប់ការចូលរួមលេង ហ្គេមនេះ",
			},
			{
				audioClip: null,
				audioClipFemale: null,
				duration: 0,
				id: "LABEL_TAP_TO_CONTINUE",
				message: "ចុចទីនេះ ដើម្បីបន្ត",
			},
			{
				audioClip: null,
				audioClipFemale: null,
				duration: 0,
				id: "LABEL_YES",
				message: "បាទ/ចាស",
			},
			{
				audioClip: null,
				audioClipFemale: null,
				duration: 0,
				id: "LABEL_NO",
				message: "ទេ",
			},
			{
				audioClip: null,
				audioClipFemale: null,
				duration: 0,
				id: "LABEL_BIKE",
				message: "ម៉ូតូ",
			},
			{
				audioClip: null,
				audioClipFemale: null,
				duration: 0,
				id: "LABEL_CAR",
				message: "ឡាន",
			},
			{
				audioClip: null,
				audioClipFemale: null,
				duration: 0,
				id: "LABEL_TAXI",
				message: "កង់បី",
			},
			{
				audioClip: null,
				audioClipFemale: null,
				duration: 0,
				id: "LABEL_BUS",
				message: "ឡានក្រុង",
			},
			{
				audioClip: null,
				audioClipFemale: null,
				duration: 0,
				id: "LABEL_ASK_FOR",
				message: "អោយតម្លៃត្រឹម៖",
			},
			{
				audioClip: null,
				audioClipFemale: null,
				duration: 0,
				id: "LABEL_SLIDE_TO_QUOTE",
				message: "រំកិលចុះឡងដើម្បីកំណត់តម្លៃដែលចងតថ្លៃ",
			},
			{
				audioClip: null,
				audioClipFemale: null,
				duration: 0,
				id: "LABEL_BUY",
				message: "ទិញ",
			},
			{
				audioClip: null,
				audioClipFemale: null,
				duration: 0,
				id: "LABEL_LEAVE",
				message: "ចាកចេញ",
			},
			{
				audioClip: null,
				audioClipFemale: null,
				duration: null,
				id: "FINAL_SCREEN_BUTTON",
				message: "បញ្ចប់ ហ្គេម",
			},
		],
	},
	sceneDataList: [
		{
			percentage: 0,
			sceneID: "PRE_GAME_INTRO",
		},
		{
			percentage: 10,
			sceneID: "HOME_PHONE_SHOPPING",
		},
		{
			percentage: 10,
			sceneID: "HOME_NUMERACY",
		},
		{
			percentage: 20,
			sceneID: "HOME_CHOOSE_TRANSPORT",
		},
		{
			percentage: 20,
			sceneID: "BIKE_TRAVEL",
		},
		{
			percentage: 20,
			sceneID: "BIKE_FILL_FUEL_NUMERACY",
		},
		{
			percentage: 20,
			sceneID: "CAR_TRAVEL",
		},
		{
			percentage: 20,
			sceneID: "CAR_FILL_FUEL_NUMERACY",
		},
		{
			percentage: 20,
			sceneID: "CAB_TRAVEL",
		},
		{
			percentage: 20,
			sceneID: "CAB_NUMERACY",
		},
		{
			percentage: 20,
			sceneID: "BUS_TRAVEL",
		},
		{
			percentage: 20,
			sceneID: "BUS_NUMERACY",
		},

		{
			percentage: 30,
			sceneID: "DESTINATION_TO_STORE",
		},

		{
			percentage: 30,
			sceneID: "STORE_AVATAR_QUERY",
		},
		{
			percentage: 40,
			sceneID: "STORE_SHOPKEEPER_RESPONSE",
		},
		{
			percentage: 40,
			sceneID: "STORE_BARGAIN_QUESTION",
		},
		{
			percentage: 50,
			sceneID: "STORE_AVATAR_BARGAIN_FIRST",
		},
		{
			percentage: 50,
			sceneID: "STORE_SHOPKEEPER_BARGAIN_FIRST",
		},
		{
			percentage: 50,
			sceneID: "STORE_AVATAR_BARGAIN_SECOND",
		},
		{
			percentage: 50,
			sceneID: "STORE_SHOPKEEPER_BARGAIN_SECOND",
		},
		{
			percentage: 50,
			sceneID: "STORE_SHOPKEEPER_PACKING",
		},
		{
			percentage: 50,
			sceneID: "AVATAR_REACH_CAFE",
		},
		{
			percentage: 60,
			sceneID: "AVATAR_SOCIAL_MEADIA_CHECK",
		},
		{
			percentage: 60,
			sceneID: "AVATAR_FRIEND_CONVERSATION_ONE",
		},
		{
			percentage: 70,
			sceneID: "FRIEND_AVATAR_CONVERSATION_ONE",
		},
		{
			percentage: 70,
			sceneID: "THREE_IMAGE_SELECTION",
		},
		{
			percentage: 70,
			sceneID: "AVATAR_FRIEND_CONVERSATION_TWO",
		},

		{
			percentage: 90,
			sceneID: "ENTER_GROCERY_SHOP",
		},

		{
			percentage: 100,
			sceneID: "RETURN_HOME",
		},
		{
			percentage: 100,
			sceneID: "GAME_END",
		},
	],
	zone: "zone-1",
};
