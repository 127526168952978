import React from "react";
import bg_init_image from "../../../assets/short-game-assets/w-pattern-bg.svg";
import "./style.scss";

const Layout = ({ children }) => {
	return (
		<>
			<div
				className="lay-out__container"
				style={{
					backgroundImage: `url(${bg_init_image})`,
				}}>
				{children}
			</div>
		</>
	);
};

export default Layout;
