import axios from "axios";
import { BASE_URL, headers } from "../../utils/authAxios";
const platform = require("platform");

export const saveResponse = (data, success, failure) => {
	axios
		.post(`${BASE_URL}/api/game/response`, data, { headers: headers })
		.then((res) => {
			success(res.data);
		})
		.catch((err) => {
			failure(err);
		});
};

export const saveAnalytics = (data, success, failure) => {
	const platformData = {
		name: platform.name,
		version: platform.version,
		product: platform.product,
		manufacturer: platform.manufacturer,
		layout: platform.layout,
		os: platform.os,
		description: platform.description,
	};
	axios
		.post(
			`${BASE_URL}/api/game/track`,
			{ platform: platformData, ...data },
			{ headers: headers }
		)
		.then((res) => {
			success();
		})
		.catch((err) => {
			failure(err);
		});
};
