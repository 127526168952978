import { BROWSER_DATA } from "../types/index";

const intialState = {};

const browserDataReducer = (state = intialState, action) => {
	switch (action.type) {
		case BROWSER_DATA:
			return action.payLoad;
		default:
			return state;
	}
};

export default browserDataReducer;
