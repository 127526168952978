import { useState, useEffect } from 'react';

const Counter = () => {
  const [seconds] = useState(240);
  const [sec, setSec] = useState(59);
  const [minutes, setMinutes] = useState(0);

  useEffect(() => {
    setMinutes(seconds / 60 - 1);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const time = setInterval(() => {
      if (minutes === 0 && sec === 0) {
        return;
      }
      if (sec === 0) {
        setMinutes((minutes) => minutes - 1);
        return setSec(59);
      }
      setSec(sec - 1);
    }, 1000);
    return () => clearInterval(time);
    // eslint-disable-next-line
  }, [sec]);
  return [minutes, sec];
};

export default Counter;
