import axios from "axios";

export let headers = {
	"Tenant-Access-Token": "OGM0MDJmYmZmYTFhZDU2ZTgzMDAwYTYwMTU1Nzg5MWE=",
	"Tenant-Access-Key": "BXLfjRJID7nEkg",
};

export const BASE_URL = "https://immersive-game-india.confirmu.com";

export const api = axios.create({
	baseURL: BASE_URL,
	headers,
});
