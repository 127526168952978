import { api, headers } from "../../utils/authAxios";
import {
	intializeSuccess,
	isFeedbackRequired,
	saveLanguages,
	saveZones,
} from "./actions";

export const intialize = (success, fail, token) => {
	headers["user-token"] = token;
	return async (dispatch) => {
		try {
			const res = await api.post(
				"/api/game/initialize",
				{},
				{ headers: headers }
			);
			if (res?.data?.error_code === 403) {
				return fail(res.data.error_code, res.data.error_text);
			}
			dispatch(intializeSuccess(true));
			dispatch(saveLanguages(res.data.languageList));
			dispatch(saveZones(res.data.zoneList));
			dispatch(isFeedbackRequired(res.data.profileFeedbackRequired));
			success();
		} catch (error) {
			fail();
		}
	};
};
