let voiceOverRef = null;

const PlayVoiceOver = (audioUrl) => {
  if (!voiceOverRef) {
    voiceOverRef = new Audio(audioUrl);
    voiceOverRef.loop = true;
  } else {
    voiceOverRef.src = audioUrl;
    voiceOverRef.volume = 0.25;
  }
  voiceOverRef.play().catch((err) => {});
  return voiceOverRef;
};

export default PlayVoiceOver;
