import { useEffect, useState } from "react";
import "./style.scss";
import SplashScreen from "../splashscreen";
import ErrorBoundary from "../errorboundary";
import GameStart from "../gamestart";
import { connect } from "react-redux";
import { intialize } from "../../store/intialize/operations";
import CuLayout from "../../utils/gameLayout";
import { Alert } from "@material-ui/lab";

function App({ isLoaded, intialize }) {
	const [failedIntilize, setFailedIntilize] = useState(false);
	const [data, setData] = useState({
		number: "",
	});
	const [msg, setMessage] = useState(
		`Couldn't load the request. Please check your internet connection and retry`
	);

	const [boolean, setBoolean] = useState(false);

	useEffect(() => {
		const path = window.location.pathname;
		const number = path.replace("/", "");
		setData({ ...data, number: number });
	}, []);

	useEffect(() => {
		if (data?.number) {
			handleClick(data.number);
		}
		// eslint-disable-next-line
	}, [data.number]);

	const success = (res) => {
		setFailedIntilize(false);
	};

	const failure = (err, text) => {
		console.log("error", err);
		if (err === 403) {
			setMessage({
				text1: "CONGRATS!",
				text2: "YOU HAVE ALREADY FINISHED THE GAME.",
			});
			setFailedIntilize(true);
		} else if (err === undefined) {
			setMessage(
				`Couldn't load the request. Please check your internet connection and retry`
			);
			setBoolean(true);
		}
	};

	const handleClick = (token) => {
		intialize(success, failure, token);
	};

	if (boolean) {
		return (
			<ErrorBoundary>
				<div className="app_alert">
					<Alert onClick={handleClick} severity="error">
						<h3>{msg}</h3>
					</Alert>
				</div>
			</ErrorBoundary>
		);
	}

	if (failedIntilize) {
		return (
			<ErrorBoundary>
				<div className="app_alert">
					<div className="app_msgBox">
						<div>
							<h1 className="app_congrats_text">{msg.text1}</h1>
						</div>
						<div>
							<h2 className="app-text2">{msg.text2}</h2>
						</div>
					</div>
				</div>
			</ErrorBoundary>
		);
	} else {
		return (
			<>
				<ErrorBoundary>
					{!isLoaded ? (
						<SplashScreen />
					) : (
						<CuLayout>
							<GameStart />
						</CuLayout>
					)}
				</ErrorBoundary>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		isLoaded: state.intialize.isLoaded,
		languages: state.intialize.languages,
	};
};

const mapDisPatchToProps = (dispatch) => {
	return {
		intialize: (success, failure, token) =>
			dispatch(intialize(success, failure, token)),
	};
};

export default connect(mapStateToProps, mapDisPatchToProps)(App);
