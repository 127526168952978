import React, { useEffect, useState } from "react";
import GameStatusBar from "../common/gamestatusbar";
import { connect } from "react-redux";
import Controls from "../common/audiocontrols/index.jsx";
import StopWatch from "../../utils/stopwatch";
import {
	totalTimeTakenInSec,
	totalTimeTakenInMilliSec,
} from "../../store/trackinguser/actions";

const CurrentComponent = ({
	children,
	scceneId,
	startStopwatch,
	totalTimeTakenInSec,
	totalTimeTakenInMilliSec,
}) => {
	const [stopwatch] = useState(new StopWatch());

	useEffect(() => {
		if (!startStopwatch) {
			stopwatch.stop();
			totalTimeTakenInMilliSec(stopwatch.getElapsedMilliseconds());
			totalTimeTakenInSec(Math.floor(stopwatch.getElapsedSeconds()));
		} else {
			stopwatch.start();
		}
		// eslint-disable-next-line
	}, [startStopwatch]);

	return (
		<>
			{scceneId === "" ||
			scceneId === undefined ||
			scceneId === "GAME_END" ||
			scceneId === "REGISTRATION" ||
			scceneId === "INTIAl_LOADING" ||
			scceneId === "PRE_GAME_INTRO" ||
			scceneId === "PRE_GAME_ASHER_INTRO" ||
			scceneId === "PRE_GAME_AVATAR_SELECTION" ? null : (
				<GameStatusBar />
			)}
			{scceneId !== "GAME_END" ? <Controls /> : null}
			{children}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		scceneId: state.screenChange.screenID,
		startStopwatch: state.screenChange.startStopWatch,
	};
};

export const mapDispatchToProps = (dispatch) => {
	return {
		totalTimeTakenInSec: (time) => dispatch(totalTimeTakenInSec(time)),
		totalTimeTakenInMilliSec: (time) =>
			dispatch(totalTimeTakenInMilliSec(time)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentComponent);
