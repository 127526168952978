import {
	saveSuffleAudio,
	saveImageDataSet,
	saveLevels,
	saveNarrationDataSet,
	saveSceneDataList,
	saveGameSessionId,
	saveSceneId,
} from "./actions";

import { shuffleData } from "../../data/shuffleData";

import { saveUserSessionId } from "../trackinguser/actions";
import { BASE_URL, headers } from "../../utils/authAxios";
import axios from "axios";

export const fetchShuffle = (data, success, failure) => {
	const [language, number, serial_number] = data;
	headers.language = language;
	headers["Phone-Number"] = number;
	headers["Serial-Number"] = serial_number;
	return (dispatch) => {
		axios
			.post(`${BASE_URL}/api/game/questions/shuffle`, {}, { headers: headers })
			.then((res) => {
				const data = res.data;
				dispatch(saveSuffleAudio(data.audioBaseUrl));
				dispatch(saveImageDataSet(data.imageDataSet));
				dispatch(saveLevels(data.levels));
				dispatch(saveNarrationDataSet(data.narrationDataSet));
				dispatch(saveSceneDataList(shuffleData.sceneDataList));
				dispatch(saveGameSessionId(data.gameSessionId));
				dispatch(saveUserSessionId(data.gameSessionId));
				const id = shuffleData.sceneDataList.map((scene) => scene.sceneID);
				dispatch(saveSceneId(id));
				success();
			})
			.catch((err) => {
				failure(err);
			});
	};
};
